$red100: #ff6557;
$red50: #ff6557c4;
$black100: black;
$grey30: #999999;
$white100: #ffffff;
$black50: #333333;
$shadowblack: rgba(0, 0, 0, 0.05);
$shadowblack2: rgba(0, 0, 0, 0.15);
$orange100: #f47a1e;
$whitepink: #fdf5e6;
$white2: #fafafa;
$whitepink2: #fff4f3;
$blue: #3471d3;
$yellow100: #fbb63a;
$pinkLite: #fef3f3;

$pink50: #fdf1f1;
$orange100: #f47a1e;
$orange50: #F8A939;
$grey100: #999999;
$grey75: #efefef;
$grey50: #f6f6f6;
$green100: #1ed760;
$blue100: #1875ca;
$blue50: #217fd8;
$gray2: #e8e8e8;
$gray3: #f8f5f5;
$gray4: #d3d3d3;
$gray5: #cfcfcf;
$grey6: #eeeeee;
$blackTransparant: #1d1d1da1;
$borderColor: #e6e6e6;
$green50: #def9e8;
$grey100: #999999;
$color-primary: #f08614;
$color-secondary: #1b655b;
$color-secondary-two: #62caca;
$color-neutral-lt: #fff;
$color-neutral-med: #ddd;
$color-neutral-dk: #444;