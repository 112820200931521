// components/ChatSupportButton.scss

.chat-support-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #00a82d; // Adjust to match your site’s color scheme
  color: white;
  border-radius: 50%;
  padding: 15px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  z-index: 1000;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }

  svg {
    font-size: 1.5rem; // Adjust icon size as needed
  }
}
