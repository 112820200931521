@import "./styles/main";

html {
  scroll-behavior: smooth;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

p,
span,
a,
li,
strong,
em,
blockquote {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

code {
  font-family: "Roboto Mono", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

a {
  text-decoration: none;
  color: inherit;
}
/* Add additional styles for other elements as needed */
$color-primary: #f08614;
$color-secondary: #1b655b;

.text-color-secondary {
  color: $color-secondary;
}
.text-color-secondary-two {
  color: $color-secondary-two;
}
.text-color-primary {
  color: $color-primary !important;
}
