// components/WhatsAppButton.scss

.whatsapp-button {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: #25d366; // WhatsApp brand color
  color: white;
  border-radius: 50%;
  padding: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }

  svg {
    font-size: 1.5rem; // Icon size
  }
}
