.not-found-container {
  margin: 100px auto;
  padding: 20px;
  max-width: 800px;
  background: linear-gradient(135deg, #fff3f3, #ffffff);
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
  overflow: hidden;
  // Combined background image with gradient
  background-image: radial-gradient(
      circle,
      rgba(240, 245, 250, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    linear-gradient(135deg, #fff3f3, #ffffff);
}

.not-found-content {
  padding: 20px;
  position: relative;
  z-index: 1;
}

.title {
  font-size: 100px;
  color: $color-primary;
  margin: 0;
}

.message {
  font-size: 18px;
  color: #333;
  margin: 20px 0;
}

.buttons {
  margin-top: 20px;
}

.button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  margin: 5px;
  transition: background-color 0.3s ease;
}

.home-button {
  background-color: #ffff;
  color: $color-primary;
  border: 1px solid $color-primary;
}

.official-button {
  background-color: $color-secondary-two;
}

.home-button:hover {
  opacity: 0.7;
}

.official-button:hover {
  background-color: darken($color-secondary-two, 10%);
}

.footer {
  text-align: center;
  font-size: 14px;
  color: #666;
}
