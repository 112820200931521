@import './colors';
@import '../abstracts/mixins';

.appPrimaryBtn {
  background-color: $red100 !important;
  color: $white100 !important;
  @include font-16-medium;
  height: 2.5rem;
  padding: 0.5rem 1.5rem;
  font-weight: 600;
}
.appPrimaryBtnSmall {
  background-color: $red100 !important;
  color: $white100 !important;
  @include font-12-medium;
  padding: 0.4rem 0.75rem;
}
.appSecondaryBtn {
  background-color: $white100 !important;
  color: $orange100 !important;
  @include font-16-medium;
  height: 2.7rem;
  padding: 0.5rem 1.5rem;
}
.appTertiaryBtn {
  background-color: $white100 !important;
  color: $black50 !important;
  @include font-16-medium;
  height: 2.7rem;
  padding: 0.5rem 1.5rem;
}
.quaternaryBtn {
  background-color: transparent !important;
  color: $red100 !important;
  @include font-16-medium;
  height: 2.575rem;
  padding: 0.5rem 1.5rem;
  border: 0.0625rem solid $orange100 !important;
}
.usercancelquaternaryBtn {
  background-color: transparent !important;
  color: $red100 !important;
  @include font-16-medium;
  height: 2.5rem;
  padding: 0.5rem 1.5rem;
  border: 0.0625rem solid $orange100 !important;
}
.quaternarySmallBtn {
  background-color: transparent !important;
  color: $red100 !important;
  @include font-12-medium;
  height: 2rem;
  line-height: 1rem;
  padding: 0.5rem !important;
  white-space: nowrap;
  border: 0.0625rem solid $orange100 !important;
}

.appOrangebgwhiteSmallBtn {
  background-color: $white100 !important;
  color: $orange100 !important;
  font-size: $font-12;
  height: 2rem;
  padding: 0.5rem !important;
  white-space: nowrap;
  border: 0.0625rem solid $orange100 !important;
}

.appPrimaryBtn:hover,
.appPrimaryBtnSmall:hover,
.appSecondaryBtn:hover,
.appTertiaryBtn:hover,
.usercancelquaternaryBtn:hover,
.quaternaryBtn:hover {
  @include box-shadow;
}

.loginButton {
  color: white;
  background-color: #ff6557;
  border: 0;
  height: 2rem;
  width: 3rem;
  border-radius: 0.25rem;
}

.continue_button {
  padding: 1px 1px 1px 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 13px 116px;
}
.continue {
  background: #ff6557 !important;
  width: 304px;
  height: 41px;
  box-shadow: 6px 8px 4px rgba(255, 101, 87, 0.15);
  border-radius: 8px !important;
  color: white !important;
}

.continue-nowidth {
  background: #ff6557 !important;
  box-shadow: 6px 8px 4px rgba(255, 101, 87, 0.15);
  border-radius: 8px !important;
  color: white !important;
  padding: 8px 24px;
}
.continue-nowidth-outline {
  background: white !important;
  // background: #ff6557 !important;
  box-shadow: 6px 8px 4px rgba(255, 101, 87, 0.15);
  border-radius: 8px !important;
  // color: white !important;
  padding: 8px 24px;
  color: #ff6557;
  border: 1px solid #ff6557;
}

.continue-nowidth-outline:hover {
  background: white !important;
  // background: #ff6557 !important;
  box-shadow: 6px 8px 4px rgba(255, 101, 87, 0.15);
  border-radius: 8px !important;
  // color: white !important;
  padding: 8px 24px;
  color: #ff6557;
  border: 1px solid #ff6557;
  font-size: 12px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 14px;
}
.continue-nowidth-new {
  background: #ff6557 !important;
  box-shadow: 6px 8px 4px rgba(255, 101, 87, 0.15);
  border-radius: 8px !important;
  color: white !important;
  // padding: 8px 24px;
  font-size: 12px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 14px;
}
.continue-nowidth-outline {
  background: white !important;
  // background: #ff6557 !important;
  box-shadow: 6px 8px 4px rgba(255, 101, 87, 0.15);
  border-radius: 8px !important;
  // color: white !important;
  // padding: 8px 24px;
  color: #ff6557;
  border: 1px solid #ff6557;
  font-size: 12px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 14px;
}

.continue-nowidth-outline:hover {
  background: white !important;
  // background: #ff6557 !important;
  box-shadow: 6px 8px 4px rgba(255, 101, 87, 0.15);
  border-radius: 8px !important;
  // color: white !important;
  padding: 8px 24px;
  color: #ff6557;
  border: 1px solid #ff6557;
  font-size: 12px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 14px;
}

.continue-outline-button {
  box-shadow: 5px 6px 11px rgba(0, 0, 0, 0.05);
  border-color: #ff6557;
  border-radius: 5px;
  padding: 8px 24px;
  font-family: Cabin;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  color: #ff6557;
  border: 1px solid !important;
  margin: 0px 16px;
}

.resend {
  font-family: 'Poppins', sans-serif;
  background: white !important;
  width: 304px;
  height: 41px;
  border-radius: 8px !important;
  color: gray !important;
}

.notificationLoad {
  display: flex !important;
  justify-content: space-evenly !important;
  margin: 40px 0 50px 0 !important;
}

.progress-custom {
  border: 0.3px solid #ff655727;
  border-radius: 2px;
  width: 100%;
  background-color: #ff65571a !important;

  .progress-bar {
    background-color: #ff6557 !important;
  }
}

.progress-customs {
  background-color: #e9ecef !important;

  .progress-bar {
    background-color: $green100 !important;
  }
}
