@mixin font-family-Comfortaa(
  $style: normal,
  $weight: normal,
  $size: medium,
  $line-height: '',
  $color: ''
) {
  font-family: 'Comfortaa', cursive;
  font-weight: $weight;
  font-style: $style;
  font-size: $size;
  @if $line-height != '' {
    line-height: $line-height;
  }
  @if $color != '' {
    color: $color;
  }
}
@mixin text-center {
  text-align: center;
  text-align: -webkit-center;
}
@mixin flex-column {
  flex-direction: column;
}
@mixin flex-row {
  flex-direction: row;
}
@mixin align-center {
  align-items: center;
}
@mixin justify-center {
  justify-content: center;
}
@mixin justify-end {
  justify-content: flex-end;
}
@mixin flex-space-between {
  justify-content: space-between;
}
@mixin flex-box {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
@mixin font-family-width(
  $style: normal,
  $weight: normal,
  $size: medium,
  $line-height: 0rem,
  $color: ''
) {
  font-weight: $weight;
  font-style: $style;
  font-size: $size;
  @if $line-height != '' {
    line-height: $line-height;
  }
  @if $color != '' {
    color: $color;
  }
}
@mixin font-30 {
  font-size: $font-30 !important;
  font-weight: 500 !important;
}
@mixin font-20 {
  font-size: $font-20;
  font-weight: 500;
}
@mixin font-18 {
  font-size: $font-18;
  font-weight: 500;
}
@mixin font-16 {
  font-size: $font-16;
}
@mixin font-16-medium {
  font-size: $font-16 !important;
  font-weight: 500 !important;
}
@mixin font-14 {
  font-size: $font-14 !important;
}
@mixin font-12 {
  font-size: $font-12;
}
@mixin font-12-medium {
  font-size: $font-12;
  font-weight: 500;
}
@mixin font-11 {
  font-size: $font-11;
}
@mixin font-10 {
  font-size: $font-10;
}
@mixin font-32 {
  font-size: $font-32;
}
@mixin box-shadow {
  box-shadow: 0.3125rem 0.3125rem 0.875rem rgba($color: $black50, $alpha: 0.1);
}
@mixin box-shadow-right {
  box-shadow: 0.25rem 0.1875rem 0.3125rem -0.0625rem rgba(0, 0, 0, 0.1),
    0.3125rem 0.1875rem 0.3125rem -0.0625rem rgba(0, 0, 0, 0.1),
    0.25rem 0.1875rem 0.125rem 0 rgba(0, 0, 0, 0.01);
}