.privacy-policy {
  margin: 0 auto; // Center align the content
  padding: 2rem;

  .header {
    text-align: center;
    margin-bottom: 2rem;
    h1 {
      font-size: 2.5rem;
      color: #333;
    }
    p {
      font-size: 1.25rem;
      color: #666;
    }
  }

  .content {
    max-width: 800px;
    margin: 0 auto;
    padding: 1rem;

    h2 {
      font-size: 1.75rem;
      color: #333;
      margin-top: 1.5rem;
      margin-bottom: 1rem;
    }

    p {
      font-size: 1rem;
      color: #444;
      line-height: 1.6;
    }

    a {
      color: #007bff;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .footer {
    text-align: center;
    margin-top: 2rem;
    p {
      font-size: 1rem;
      color: #666;
    }
  }
}
