// components/CookieConsent.scss
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

.cookie-consent {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fff;
  border-top: 1px solid #ddd;
  padding: 20px;
  box-shadow: 0 -2px 15px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  font-family: "Roboto", sans-serif;

  .cookie-consent-content {
    display: flex;
    align-items: center;
    max-width: 1200px;
    width: 100%;
    justify-content: space-between;
    padding: 0 20px;

    .cookie-icon {
      font-size: 2.5rem;
      margin-right: 20px;
      color: #f08614; // Use a bright color to catch attention
    }

    p {
      margin: 0;
      flex: 1;
      font-size: 1rem;
      line-height: 1.5;

      a {
        color: #007bff; // Link color
        text-decoration: underline;
        transition: color 0.3s;

        &:hover {
          color: #0056b3; // Darker shade on hover
        }
      }
    }

    .cookie-consent-buttons {
      display: flex;
      gap: 15px;

      button {
        border: 2px solid transparent;
        border-radius: 4px;
        padding: 10px 20px;
        cursor: pointer;
        font-size: 1rem;
        font-family: "Roboto", sans-serif;
        transition: background 0.3s, color 0.3s, border-color 0.3s;
        display: flex;
        align-items: center;
        gap: 10px;

        &.accept-button {
          background: #f08614; // Primary brand color
          color: #fff; // White text color
          border-color: #f08614; // Border color matching the background
          font-weight: bold;

          &:hover {
            background: #d56a12; // Slightly darker shade for hover effect
            border-color: #d56a12; // Border color for hover effect
          }
        }

        &.decline-button {
          background: #fff; // White background
          color: #1b655b; // Text color matching the secondary brand color
          border-color: #1b655b; // Border color matching the text color
          font-weight: bold;

          &:hover {
            background: #f2f2f2; // Light grey background for hover effect
            color: #145d49; // Darker text color for hover effect
            border-color: #145d49; // Border color for hover effect
          }
        }

        .button-icon {
          font-size: 1.2rem; // Icon size
        }
      }
    }
  }
}
