.customer-support-page {
//   max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  // font-family: "Arial", sans-serif;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  .support-header {
    text-align: center;
    margin-bottom: 20px;

    h1 {
      font-size: 2rem;
      color: #333;
    }

    p {
      font-size: 1rem;
      color: #666;
    }
  }

  .contact-info {
    margin-bottom: 30px;
    padding: 20px;
    background-color: #e9f5f5;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    h2 {
      font-size: 1.5rem;
      color: #333;
      margin-bottom: 10px;
    }

    p {
      font-size: 1rem;
      color: #555;
      margin-bottom: 15px;
    }

    ul {
      list-style: none;
      padding: 0;

      li {
        display: flex;
        align-items: center;
        font-size: 1rem;
        color: #333;
        margin-bottom: 10px;

        .icon {
          margin-right: 10px;
          color: #62caca;
        }

        a {
          color: #62caca;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .faq-section {
    h2 {
      font-size: 1.5rem;
      color: #333;
      margin-bottom: 15px;
    }

    p {
      font-size: 1rem;
      color: #666;
      margin-bottom: 15px;
    }

    .faq-item {
      background-color: #fff;
      border: 1px solid #ddd;
      border-radius: 5px;
      padding: 15px;
      margin-bottom: 10px;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        background-color: #f0f0f0;
        border-color: #ccc;
      }

      &.expanded {
        background-color: #e9f5f5;
        border-color: #62caca;
      }

      .faq-question {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1.1rem;
        color: #333;
        font-weight: bold;

        .icon {
          margin-left: 10px;
          font-size: 1.2rem;
          color: #62caca;
          transition: transform 0.2s ease;
        }
      }

      .faq-answer {
        margin-top: 10px;
        font-size: 1rem;
        color: #555;
        line-height: 1.5;
        animation: fadeIn 0.3s ease;
      }

      // Keyframe for fade-in animation
      @keyframes fadeIn {
        from {
          opacity: 0;
          transform: translateY(-10px);
        }
        to {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }
}
