// TermsAndConditions.scss

// Define colors directly
$color-primary-two: #1b655b; // Primary color
$color-secondary: #636363; // Secondary color
$color-secondary-dark: #e64a19; // Darker shade of secondary color
$gray-200: #757575; // Gray color for text
$gray-600: #757575; // Gray color for text
$gray-700: #616161; // Darker gray for text

.terms-and-conditions {
  //   font-family: "Arial", sans-serif; // Clean, web-safe font
  max-width: 800px; // Max width for better readability
  margin: 0 auto; // Center align the content
  padding: 2rem;

  .header {
    text-align: center;
    margin-bottom: 2rem;

    h1 {
      color: $color-primary-two;
      font-size: 2.5rem;
      margin-bottom: 0.5rem;
    }

    p {
      color: $gray-600;
      font-size: 1.125rem;
      line-height: 1.6;
    }
  }

  .content {
    margin-bottom: 2rem;

    h2 {
      color: $color-primary-two;
      font-size: 1.75rem;
      margin-bottom: 1rem;
      border-bottom: 2px solid $color-secondary;
      padding-bottom: 0.5rem;
    }

    p {
      color: $gray-700;
      font-size: 1rem;
      line-height: 1.6;
      margin-bottom: 1rem;
    }

    a {
      color: $color-secondary-dark;
      text-decoration: none;
      border-bottom: 1px solid $color-secondary-dark;

      &:hover {
        color: $color-secondary-dark;
        border-bottom: 1px solid $color-secondary-dark;
      }
    }
  }

  .footer {
    text-align: center;
    padding-top: 2rem;
    border-top: 1px solid $gray-200;

    p {
      color: $gray-600;
      font-size: 0.875rem;

      a {
        color: $color-secondary-dark;
        text-decoration: none;

        &:hover {
          color: $color-secondary-dark;
        }
      }
    }
  }
}
