// components/OfferModal.scss
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

.offer-modal {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 400px; /* Adjust width as needed */
  background: #fff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  z-index: 1001;
  font-family: "Roboto", sans-serif;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .offer-modal-content {
    display: flex;
    align-items: center;
    padding: 20px;
    gap: 30px;

    .offer-image {
      width: 120px; /* Adjust width as needed */
      height: 120px; /* Adjust height as needed */
      object-fit: cover;
      border-radius: 8px;
      margin-right: 20px;
    }

    .offer-details {
      flex: 1;

      h2 {
        margin: 0 0 10px;
        color: #f08614; /* Brand color */
        font-size: 1.25rem;
      }

      p {
        margin: 0 0 15px;
        color: #333;
        font-size: 1rem;
      }

      .offer-buttons {
        display: flex;
        gap: 10px;

        .action-button {
          border: none;
          padding: 10px 15px;
          cursor: pointer;
          font-size: 0.9rem;
          font-family: "Roboto", sans-serif;
          border-radius: 4px;
          transition: background 0.3s, color 0.3s, border-color 0.3s;

          &.primary-button {
            background: #f08614; /* Brand color */
            color: #fff;

            &:hover {
              background: #d56a12; /* Darker shade */
            }
          }

          &.secondary-button {
            background: #fff;
            color: #1b655b; /* Secondary brand color */
            border: 2px solid #1b655b;

            &:hover {
              background: #f2f2f2;
              color: #145d49; /* Darker shade */
            }
          }
        }
      }
    }
  }
}
