// $color-primary: #f08614;
// $color-secondary: #1b655b;
// $color-secondary-two: #62caca;
// $color-light: #f9f9f9;
// $color-card-bg: #ffffff;

// .about-us-page {
//   padding: 40px;
//   background-color: $color-light;
//   color: $color-secondary;
//   font-family: "Arial", sans-serif;

//   .about-us-container {
//     max-width: 1200px;
//     margin: 0 auto;
//     display: flex;
//     flex-direction: column;
//     align-items: center;

//     .about-us-title {
//       font-size: 36px;
//       margin-bottom: 30px;
//       font-weight: bold;
//       background: linear-gradient(to right, $color-primary, $color-secondary);
//       -webkit-background-clip: text;
//       -webkit-text-fill-color: transparent;
//     }

//     .about-us-steps {
//       display: flex;
//       flex-direction: column;
//       width: 100%;
//       max-width: 800px;

//       .step-card {
//         display: flex;
//         background-color: $color-card-bg;
//         border-radius: 8px;
//         box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//         margin-bottom: 20px;
//         padding: 20px;
//         position: relative;
//         align-items: center;

//         &.reverse {
//           flex-direction: row-reverse;
//         }

//         .step-image {
//           flex: 1;
//           img {
//             max-width: 100%;
//             height: auto;
//             border-radius: 8px;
//           }
//         }

//         .step-content {
//           flex: 1;
//           padding: 0 20px;

//           .step-heading {
//             font-size: 28px;
//             color: $color-primary;
//             margin-bottom: 10px;
//             display: flex;
//             align-items: center;

//             .step-icon {
//               font-size: 24px;
//               color: $color-secondary;
//               margin-right: 10px;
//             }
//           }

//           p {
//             font-size: 18px;
//             line-height: 1.6;
//           }
//         }
//       }
//     }
//   }

//   .about-us-footer {
//     margin-top: 40px;
//     font-size: 16px;
//     color: $color-secondary;

//     a {
//       color: $color-primary;
//       text-decoration: none;
//       font-weight: bold;
//     }
//   }
// }

.about-us-page {
  position: relative;
  overflow: hidden;
  padding: 20px;
  background-color: white;

  .about-us-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;

    .about-us-title {
      font-size: 36px;
      font-weight: bold;
      text-align: center;
      color: $color-primary;
      margin-bottom: 40px;
    }

    .about-us-steps {
      position: relative;
    }

    .step-card {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: white;
      border-radius: 10px;
      padding: 20px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      margin-bottom: 60px;
      position: relative;
      overflow: hidden;

      &.reverse {
        flex-direction: row-reverse;
      }

      .step-image {
        flex: 1;
        img {
          width: 100%;
          border-radius: 10px;
        }
      }

      .step-content {
        flex: 1;
        padding: 0 20px;

        .step-heading {
          font-size: 24px;
          color: $color-secondary;
          margin-bottom: 10px;
          display: flex;
          align-items: center;

          .step-icon {
            margin-right: 10px;
            font-size: 28px;
            color: $color-secondary-two;
          }
        }

        p {
          font-size: 16px;
          color: #333;
          line-height: 1.6;
        }
      }
    }

    .path-connector {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      z-index: -1;

      .path-line {
        width: 2px;
        height: 80px;
        background-color: $color-secondary;
        position: relative;
      }

      .path-circle {
        width: 20px;
        height: 20px;
        background-color: $color-secondary;
        border-radius: 50%;
        position: relative;
      }
    }
  }

  .blob {
    position: absolute;
    width: 200px;
    height: 200px;
    opacity: 0.1; /* Adjust for desired visibility */
    z-index: -1; /* Send blobs behind content */
  }

  .blob-left {
    top: 0;
    left: 0;
  }

  .blob-right {
    bottom: 0;
    right: 0;
  }

  .about-us-footer {
    text-align: center;
    font-size: 14px;
    color: #666;
    margin-top: 40px;

    a {
      color: $color-primary;
      text-decoration: none;
    }
  }
}
