// components/SocialMediaSidebar.scss
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

.social-media-sidebar {
  position: fixed;
  bottom: 50%;
  transform: translateY(50%);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 10px;

  // Uncomment this block for the left side
  left: 0;
  align-items: center;
  padding: 0 10px;

  // Uncomment this block for the right side
  // right: 0;
  // align-items: center;
  // padding: 0 10px;

  .social-icon {
    background: #fff;
    border-radius: 50%;
    padding: 8px; // Adjust padding for a smaller icon size
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: background 0.3s, color 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background: #f08614; // Primary brand color
      color: #fff;
    }

    svg {
      font-size: 1.2rem; // Smaller icon size
    }
  }
}
