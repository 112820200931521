.search_input {
  .textbox {
    background: #ffffff;
    border: 1px solid #fafafa;
    box-sizing: border-box;
    box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.05);
    min-width: 102%;
    height: 40px;
    border-radius: 8px;
    @include font-family-width(normal, normal, 12px, '', $grey30);
    padding-left: 35px;
    outline: 0 !important;
    padding-right: 0px;
  }
  .left-icon {
    color: $grey30;
    position: absolute;
    padding: 14px;
    font-size: 14px;
  }
  .right-icon {
  }
}
