.blogListStyle {
  justify-content: center;
  display: flex;
}

.scrollbarNone {
  scrollbar-width: none;
}

// Primary button styles
.primaryButton {
  background-color: $color-primary;
  color: white; // Assuming white text for better contrast

  &:hover {
    opacity: 0.8; // Adjusted to 0.8 for better visibility on hover
    background-color: darken(
      $color-primary,
      10%
    ); // Darken the color on hover for a visual effect
    transition: background-color 0.3s ease, opacity 0.3s ease; // Smooth transition
  }

  // Optional: Adding focus styles for accessibility
  &:focus {
    outline: 2px solid $color-primary;
    outline-offset: 2px;
  }
}

// // Define animation keyframes for the focus effect
// @keyframes focusEffect {
//   0% {
//     border-color: $color-primary;
//     box-shadow: 0 0 0 2px $color-primary;
//     border-top-left-radius: 0.5rem;
//     border-bottom-left-radius: 0.5rem;
//   }
//   50% {
//     border-color: $color-secondary;
//     box-shadow: 0 0 0 2px $color-secondary;
//     border-top-left-radius: 0.5rem;
//     border-bottom-left-radius: 0.5rem;
//   }
//   100% {
//     border-color: $color-primary;
//     box-shadow: 0 0 0 2px $color-primary;
//     border-top-left-radius: 0.5rem;
//     border-bottom-left-radius: 0.5rem;
//   }
// }

// // Input button styles
// .inputButton {
//   &:focus {
//     outline: none;
//     animation: focusEffect 1s infinite; // Apply the animation
//     border-color: $color-primary; // Ensure consistent border color when not animating
//     box-shadow: 0 0 0 2px $color-primary; // Consistent shadow for focus
//     border-top-left-radius: 0.5rem;
//     border-bottom-left-radius: 0.5rem;
//   }
// }

// Define animation keyframes for the focus effect
@keyframes focusEffect {
  0% {
    border-color: $color-primary;
    box-shadow: 0 0 0 2px $color-primary;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
  }
  50% {
    border-color: $color-secondary;
    box-shadow: 0 0 0 4px $color-secondary; // Increase shadow width for impact
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
  }
  100% {
    border-color: $color-primary;
    box-shadow: 0 0 0 2px $color-primary;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
  }
}

// Input button styles
.inputButton {
  // Keep existing styles intact
  &:focus {
    outline: none;
    animation: focusEffect 1s infinite; // Apply the updated animation
    border-color: $color-primary; // Ensure consistent border color during focus
    box-shadow: 0 0 0 2px $color-primary; // Consistent shadow for focus
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
  }
}

.dropdownNavBarStyle {
  color: $color-secondary !important;
}

.active {
  color: $color-primary !important;
}

.linkHover {
  &:hover {
    color: $color-primary !important;
  }
}

.svgColorStyle {
  color: $color-secondary-two;
}

/* Styles for the carousel container */
.scroll-container {
  display: flex;
  overflow: hidden;
  width: 100%;
  scroll-behavior: smooth;
  white-space: nowrap;
  position: relative;
}

/* Keyframes for scrolling */
@keyframes scroll-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* Apply the animation to the scroll container */
.scroll-container {
  animation: scroll-left 15s linear infinite;
}

/* Styles for the items inside the container */
.scroll-item {
  flex: 0 0 auto;
  width: 100%; /* Adjust width as needed */
  display: inline-block;
}

.hero-carousel .slick-slide {
  display: flex;
  justify-content: center;
}

.hero-carousel .slick-prev,
.hero-carousel .slick-next {
  z-index: 1;
}

.hero-carousel .slick-dots {
  bottom: -30px;
}
.hero-carousel {
  width: 100%;
  max-width: 1200px; /* Adjust as needed */
  margin: auto;
}

.hereSectionRightSide {
  z-index: 99;
}

.socialMediaIcon {
  background-color: $color-secondary;
  &:hover {
    background-color: $color-primary;
  }
}

.footerLogoStyle {
  display: flex;
  align-items: center;
}

.loading-container {
  display: flex;
  flex-direction: column; // Arrange spinner and text vertically
  justify-content: center; // Center vertically
  align-items: center; // Center horizontally
  height: 100vh; // Full viewport height
  width: 100vw; // Full viewport width
  background-color: #f5f5f5; // Optional background color for better visibility
}

.spinner {
  border: 8px solid #f3f3f3; /* Light grey background */
  border-top: 8px solid $color-primary; /* Blue color for the spinner */
  border-radius: 50%;
  width: 50px; /* Size of the spinner */
  height: 50px; /* Size of the spinner */
  animation: spin 1s linear infinite; /* Spin animation */
}

.loading-text {
  margin-top: 10px; /* Space between spinner and text */
  font-size: 16px;
  color: #333;
  // font-family: Arial, sans-serif;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.infinity-spinner-container {
  display: flex;
  flex-direction: column; // Arrange spinner and text vertically
  justify-content: center; // Center vertically
  align-items: center; // Center horizontally
  height: 100vh; // Full viewport height
  width: 100vw; // Full viewport width
  background-color: #f5f5f5; // Optional background color for better visibility
}

.infinity-spinner {
  position: relative;
  width: 80px; /* Size of the spinner */
  height: 80px; /* Size of the spinner */
}

.infinity-spinner::before,
.infinity-spinner::after {
  content: "";
  position: absolute;
  border: 8px solid transparent;
  border-radius: 50%;
  border-top-color: $color-secondary; /* Spinner color */
  width: 80px; /* Match the size of the spinner */
  height: 80px; /* Match the size of the spinner */
  animation: spin 1.5s linear infinite; /* Spinner animation */
}

.infinity-spinner::after {
  border-top-color: $color-primary; /* Different color for the second loop */
  animation: spin 1.5s linear infinite reverse; /* Reverse animation for the second loop */
}

.infinity-spinner::before {
  animation-delay: -0.75s; /* Stagger the animations to create an infinite loop effect */
}

.loading-text {
  margin-top: 10px; /* Space between spinner and text */
  font-size: 16px;
  color: #333;
  // font-family: Arial, sans-serif;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.infinity-spinner-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: #f5f5f5; // Optional background color for better visibility
}

.infinity-spinner {
  position: relative;
  width: 80px; // Size of the spinner
  height: 80px; // Size of the spinner
}

.spinner-loop {
  position: absolute;
  width: 60px; // Size of each loop
  height: 60px; // Size of each loop
  border: 8px solid transparent;
  border-radius: 50%;
  border-top-color: $color-primary; // Color of the loops
  animation: spin 1.5s linear infinite;
}

.loop-1 {
  border-left-color: transparent; // Create the figure-eight effect
  border-right-color: transparent; // Create the figure-eight effect
  transform: rotate(45deg);
}

.loop-2 {
  border-top-color: transparent;
  border-bottom-color: transparent;
  transform: rotate(-45deg);
}

.spinner-text {
  margin-top: 10px;
  font-size: 16px;
  color: #333;
  // font-family: Arial, sans-serif;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.AcceptCookies {
  color: $color-primary;
}
.DeclineCookies {
  color: $color-secondary;
}
